<template>
  <el-dialog :visible.sync="dialogVisible" class="block">
    <el-timeline>
      <el-timeline-item v-for="(item,index) in array" :key="index" :timestamp="item.createTime" placement="top">
        <el-card>
          <h4>{{item.createBy}}</h4>
          <p>上传：{{item.approveStatus?'成功':'失败'}}</p>
          <p>{{item.message}}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="loading" type="primary" @click="submit">{{$l("common.cancel", "重新上传")}}</el-button>

    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "96333detail",
    data() {
      return{dialogVisible:false,id:"",array:[],loading:false};
    },
    methods:{
      getData(id) {
        this.$http.get(`message/message-approve/${id}`).then(res=>{
          this.array = res;
          console.log(res,38);
        });
      },
      open(id) {
        this.id  =id;
        this.getData(id);
        this.dialogVisible= true;
      },
      submit() {
        this.loading =true;
        this.$http
          .post("maintenance/maintain-order/"+this.id+"/approved", {approvalRemark:"重新上传96333"})
          .then(() => {
            setTimeout(()=>{
              this.submitLoading = false;
              this.dialogVisible = false;
              this.loading=false;
            },5000);

            // this.$emit("save-success");
            // this.$message.success("审批成功");
          }).catch(() => {
            this.loading=false;
            this.submitLoading = false;
          });

      },
    },

  };
</script>

<style scoped>

</style>
