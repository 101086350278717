<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/maintain-order/page"
    >
      <template slot="adSearch">
        <div class="vm-search">
          <div class="vm-search">
            <vm-search :label="$l('project.name','工单编号')">
              <el-input v-model.trim="filter.orderCode" clearable></el-input>
            </vm-search>
            <vm-search label="保养类型">
              <vm-dict-select v-model="filter.type" type="maintainType"></vm-dict-select>
            </vm-search>
            <vm-search label="计划保养日期">
              <el-date-picker
                v-model="filter.pdTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>


            <vm-search label="注册代码">
              <el-input v-model.trim="filter.regCode" clearable></el-input>
            </vm-search>
            <vm-search label="维保班组">
              <el-input v-model.trim="filter.teamName" clearable></el-input>
            </vm-search>
            <vm-search label="队长姓名">
              <el-input v-model.trim="filter.teamLeaderName" clearable></el-input>
            </vm-search>
            <vm-search label="队长电话">
              <el-input v-model.trim="filter.teamLeaderPhone" clearable></el-input>
            </vm-search>
            <vm-search label="认领超时时间">
              <el-date-picker
                v-model="filter.atoTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="是否认领超时">
              <el-select v-model="filter.assignedTimeout">
                <el-option :value="true" label="是"></el-option>
                <el-option :value="false" label="否"></el-option>
              </el-select>
            </vm-search>

            <vm-search label="到达时间">
              <el-date-picker
                v-model="filter.arTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="完成超时时间">
              <el-date-picker
                v-model="filter.ctoTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="是否完成超时">
              <el-select v-model="filter.completedTimeout">
                <el-option :value="true" label="是"></el-option>
                <el-option :value="false" label="否"></el-option>
              </el-select>
            </vm-search>
            <vm-search label="实际完成时间">
              <el-date-picker
                v-model="filter.coTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="审批时间">
              <el-date-picker
                v-model="filter.apTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleTime">
              </el-date-picker>
            </vm-search>
            <vm-search label="楼盘">
              <el-input v-model.trim="filter.realEstateName" clearable></el-input>
            </vm-search>
          </div>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="assigneeName" label="认领人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.assigneeName}}/{{scope.row.assigneePhone}}
        </template>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号"></el-table-column>
      <el-table-column prop="typeDesc" label="保养类型">
        <template slot-scope="scope">
          {{scope.row.planDate?scope.row.typeDesc:scope.row.typeDesc+'(按需)'}}
        </template>
      </el-table-column>
      <el-table-column prop="planDate" label="计划保养日期"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="注册代码"></el-table-column>
      <el-table-column prop="createTime" label="品牌/型号">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="认领时间">
        <template slot-scope="scope">
          <span v-if="scope.row.assignedTimeoutLevel">
            {{scope.row.assignedTime}}({{scope.row.assignedTimeoutLevel}}级超时)
          </span>
          <span v-else>{{scope.row.assignedTime}}(未超时)</span>
        </template>
      </el-table-column>>
      <el-table-column prop="arrivedTime" label="到达时间"></el-table-column>
      <el-table-column prop="createTime" label="完成时间">
        <template slot-scope="scope">
          <span v-if="scope.row.completedTimeoutLevel">
            {{scope.row.completedTime}}({{scope.row.completedTimeoutLevel}}级超时)
          </span>
          <span v-else>{{scope.row.completedTime}}(未超时)</span>
        </template>
      </el-table-column>
      <el-table-column prop="approvedTime" label="审批时间"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('审批完成打印预览')" type="primary" @click="$refs.halfMonthPrint.open(scope.row.id)">{{$l("common.detail", "打印预览")}}</el-button>
          <el-button v-if="$auth('审批完成详情')" type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <half-month-print ref="halfMonthPrint" @save-success="getList(-1)"></half-month-print>
  </div>
</template>
<script>
  import EditPage from "./MaintenanceDetailNew";
  import HalfMonthPrint from "@/views/maintenance/maintenance/maintenanceOrder/HalfMonthPrint";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: { EditPage,HalfMonthPrint},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          orderStatus: "maintaining",
          orderColumnName:"id",
          completedTimeout: true,
          companyId: loginUtil.getCompanyCode(),
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleTime() {
        if(this.filter.pdTime !=null) {
          this.filter.planDateFrom = this.filter.pdTime[0];
          this.filter.planDateTo = this.filter.pdTime[1];
        }
        if(this.filter.atoTime !=null) {
          this.filter.assignedTimeoutTimeFrom = this.filter.atoTime[0];
          this.filter.assignedTimeoutTimeTo = this.filter.atoTime[1];
        }
        if(this.filter.arTime !=null) {
          this.filter.arrivedTimeFrom = this.filter.arTime[0];
          this.filter.arrivedTimeTo = this.filter.arTime[1];
        }
        if(this.filter.ctoTime !=null) {
          this.filter.completedTimeoutTimeFrom = this.filter.ctoTime[0];
          this.filter.completedTimeoutTimeTo = this.filter.ctoTime[1];
        }
        if(this.filter.coTime !=null) {
          this.filter.completedTimeFrom = this.filter.coTime[0];
          this.filter.completedTimeTo = this.filter.coTime[1];
        }
        if(this.filter.apTime !=null) {
          this.filter.approvedTimeFrom = this.filter.apTime[0];
          this.filter.approvedTimeTo = this.filter.apTime[1];
        }
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.projectName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.projectName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
